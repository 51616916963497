.List {
  display: flex;
  flex-wrap: wrap;
  gap: var(--offsetS);
  width: 100%;
  margin: var(--offsetZero);
  padding: var(--offsetZero);
  padding-bottom: var(--offsetM);

  list-style: none;
}

.ListItem {
  position: relative;

  width: 100%;
  padding: var(--offsetS);

  background-color: var(--color7);
  border-radius: var(--borderRadius);
}

.Close {
  position: absolute;
  top: var(--offsetM);
  right: var(--offsetM);
}
