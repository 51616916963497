.SubTitle {
  margin-bottom: var(--offsetZero);
}

.CodeBlock {
  margin: var(--offsetM) var(--offsetZero);
}

.CopyHref {
  position: absolute;

  transform: translateX(calc(-100% - (var(--offsetXL) - 100%) / 2));
}

.Accordion {
  width: 100%;
}

.Props {
  flex-direction: column;
  align-items: flex-start;
}

.PropsList {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: var(--offsetZero);
  padding: var(--offsetZero);
}

.PropContainer {
  width: 100%;

  list-style: none;

  .Name {
    margin-right: var(--offsetS);
    margin-bottom: var(--offsetS);

    font-weight: 700;

    --CaptionColor: var(--color10);
  }

  .Type {
    margin-bottom: var(--offsetS);

    font-weight: 700;
    font-style: italic;
  }

  .ShowDefinition {
    display: inline-block;

    color: var(--color10);

    font-weight: 700;
    font-size: var(--fontS);
    font-style: italic;
    line-height: var(--lineHeightL);
  }

  .Description {
    margin-top: var(--offsetZero);
  }
}
